<section class="section about" id='about'>
        <div class="container">
                <div class="section-box">
                        <div class="about-title" data-aos="fade-up">
                                <h3 class="section-title">
                                        <span class='n-section-title'>01.</span> {{"AboutMe.Title" | translate}}
                                </h3>
                        </div>
                        <div class="row">
                                <div class="col-12 col-md-6 mb-4 mb-md-0">
                                        <div class="about-description">
                                                <p *ngFor='let text of "AboutMe.Paragraphs" | translate'
                                                        [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                                                </p>
                                                <ul class="skills-list" data-aos="fade-up">
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span class="underline">Python
                                                                </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span class="underline">Jupyter
                                                                        Notebook </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span
                                                                        class="underline">Scikit-Learn </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span
                                                                        class="underline">TensorFlow </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span class="underline">PyTorch
                                                                </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span class="underline">Angular
                                                                </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span
                                                                        class="underline">TypeScript </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span
                                                                        class="underline">JavaScript </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span class="underline">HTML
                                                                        &amp; (S)CSS </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span class="underline">NodeJS
                                                                </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span class="underline">Express
                                                                </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span class="underline">GitLab
                                                                </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span class="underline">Docker
                                                                </span></li>
                                                        <li class="skill-element" data-aos="fade-up"
                                                                data-aos-duration="1000"><span class="underline">CI / CD
                                                                </span></li>
                                                </ul>
                                        </div>
                                </div>
                                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up"
                                        data-aos-duration="1000">
                                        <div class="about-img-container">
                                                <img class="rounded" width="400" height="300"
                                                        src="assets/images/portrait.png" alt="Adrien LAIGLE">
                                        </div>
                                </div>

                        </div>
                </div>
        </div>
</section>