<section class="section banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <h1>{{"Banner.Pretitle" | translate}}</h1>
                </div>
                <div class='banner-title'>
                    <h2>Adrien Laigle</h2>
                    <h3>AI & Data Engineer</h3>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description" | translate' class="mt-4"></p>
                </div>
            </div>
            <div class="div-btn-banner">
                <a href="mailto:laigle@et.esiea.fr" target="_black" class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </a>
            </div>
        </div>
    </div>
    <div class="profil-container"></div>

</section>