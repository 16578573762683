<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <li>
                <a href="https://github.com/AdrienLgl" target='_blank'>
                    <i class="fab fa-github"></i>
                </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/in/adrienlaigle/" target='_blank'>
                    <i class="fab fa-linkedin-in"></i>
                </a>
            </li>
        </ul>
    </div>


</footer>